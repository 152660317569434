import { Suspense, lazy, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module'

import Layout from './layout/Layout';
import PagePrivacyPolicy from './pages/PagePrivacyPolicy';
import PageSupport from './pages/PageSupport';
import DataContext from './Context';

import './scss/main.scss';

function App() {

    useEffect(() => {
      TagManager.initialize({
        gtmId: 'GTM-KGDJFPC7'
      })
    }, [])


  const [currentLang, setCurrentLang] = useState('en');

  const PageLanding = lazy(() => import('./pages/PageLanding'));
  const PageThankYou = lazy(() => import('./pages/PageThankYou'));
  const PagePlanTheFuture = lazy(() => import('./pages/PagePlanTheFuture'));
  const PageToSuccess = lazy(() => import('./pages/PageToSuccess'));
  const PageHayBoss = lazy(() => import('./pages/PageHayBoss'));
  const PageJoinOurRetailer = lazy(() => import('./pages/PageJoinOurRetailer'));


   return (
    <DataContext.Provider value={{ currentLang, setCurrentLang }}>
      <Suspense>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<PageLanding title="Eco-Friendly Auto Parts" />} />
            <Route
              path="success"
              element={<PageThankYou title="Thank You for Joining Our Cause" />}
            />
            <Route path="privacy-policy" element={<PagePrivacyPolicy title="Privacy-policy" />} />
            <Route path="support" element={<PageSupport title="Support" />} />
            <Route
              path="webinars"
              element={
                <PagePlanTheFuture title="Webinar Registration | Help Save the Environment" />
              }
            />
            <Route
              path="partnership"
              element={<PageToSuccess title="Eco-friendly Partnership Registration" />}
            />
            <Route
              path="eco-friendly-marketplace"
              element={
                <PageHayBoss title="HEYBOSS: Eco-Friendly Marketplace | Reuse Car Parts, Reduce CO2 Emissions" />
              }
            />
            <Route
              path="retailers"
              element={
                <PageJoinOurRetailer title="Join HEYBOSS Retail Network | Eco-Friendly Growth and Rewards" />
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </DataContext.Provider>
  );
}

export default App;
