import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import ModalMenuBurger from '../components/ModalMenuBurger';
import LangMenu from '../components/LangMenu';
import Logo from '../components/Logo';
import { NavLink } from 'react-router-dom';

function Header() {
  const [burgerIsOpen, setBurgerIsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (burgerIsOpen) {
      document.documentElement.classList.add('disable-scroll');
      window.scrollTo(0, 0);
    } else {
      document.documentElement.classList.remove('disable-scroll');
    }

    return () => {
      document.documentElement.classList.remove('disable-scroll');
    };
  }, [burgerIsOpen]);

  return (
    <>
      <header id="header" className="header bg-header bg-c-dark flex jcsb aic">
        <div className="container">
          <div className="flex jcsb aic">
            <Logo setBurgerIsOpen={setBurgerIsOpen} />
            <nav>
              <ul className="header__navigation flex fdr pr p-0 lsn">
                <li>
                  <HashLink
                    to="/#about_us"
                    className="text-link c-white"
                    aria-label="Link to About us section"
                  >
                    {t('About us')}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/#partnership"
                    className="text-link c-white"
                    aria-label="Link to Together to common success section"
                  >
                    {t('Partnership')}
                  </HashLink>
                </li>
              </ul>
            </nav>
            <div className="flex aic">
              <div className="header__right-block">
                <LangMenu />
                <NavLink
                  to="/eco-friendly-marketplace"
                  className="width-max-content button btn-header c-white bg-c-gold bbl"
                  aria-label="Find Parts"
                  onClick={() => setBurgerIsOpen(false)}
                >
                  {t('Find Parts')}
                </NavLink>
              </div>
              <div
                className={`burger ${burgerIsOpen ? 'open' : ''}`}
                onClick={() => setBurgerIsOpen(!burgerIsOpen)}
              >
                <span className="tt"></span>
                <span className="mm"></span>
                <span className="bb"></span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ModalMenuBurger burgerIsOpen={burgerIsOpen} setBurgerIsOpen={setBurgerIsOpen} />
    </>
  );
}

export default Header;
