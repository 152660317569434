import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// Google Analytics
import ReactGA from 'react-ga4'
//
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css'
import App from "./App.jsx";
import "normalize.css";

// Google Analytics
ReactGA.initialize("GTM-KGDJFPC7")
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname
})
//

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
