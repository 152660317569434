import { Link } from 'react-router-dom';

import LogoPath from '../assets/icons/logo.svg';

function Logo({ setBurgerIsOpen }) {
  return (
    <>
      <div className="logo">
        <Link
          to="/"
          onClick={() => {
            document.documentElement.scrollTop = 0;
            setBurgerIsOpen(false);
          }}
        >
          <img src={LogoPath} alt="logo" />
        </Link>
      </div>
    </>
  );
}

export default Logo;
