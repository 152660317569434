import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import scrollToTop from '../helpers/ScrollToTop';

function ModalMenuBurger({ burgerIsOpen, setBurgerIsOpen }) {
  const { t } = useTranslation();

  const closeMenu = () => {
    setBurgerIsOpen(false);
  };

  return (
    <div className={`burger-menu ${burgerIsOpen ? 'active' : ''}`}>
      <ul>
        <NavLink to="/webinars" onClick={closeMenu}>
          <li>{t('About US')}</li>
        </NavLink>
        <NavLink to="/partnership" onClick={closeMenu}>
          <li>{t('Partnership')}</li>
        </NavLink>
        <NavLink
          to="/retailers"
          onClick={() => {
            scrollToTop();
            closeMenu();
          }}
        >
          <li>{t('Retail network')}</li>
        </NavLink>
        <NavLink
          to="/privacy-policy"
          onClick={() => {
            scrollToTop();
            closeMenu();
          }}
        >
          <li>{t('Privacy policy')}</li>
        </NavLink>
      </ul>
    </div>
  );
}

export default ModalMenuBurger;
