import React, { useRef, useEffect, useState, useContext } from 'react';

import FlagEn from '../assets/icons/flag_en.svg';
import FlagUa from '../assets/icons/flag_ua.svg';
import i18n from '../localization';
import DataContext from '../Context';

function LangMenu() {
  const { currentLang, setCurrentLang } = useContext(DataContext);
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);
  const langMenuRef = useRef(null);

  const setLanguage = (lng) => {
    setCurrentLang(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem('storageLang', lng);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
        setSelectorIsOpen(false);
      }
    };
    const storageLang = localStorage.getItem('storageLang');

    storageLang ? setLanguage(storageLang) : setLanguage('en');

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="lang-menu"
        ref={langMenuRef}
        onClick={() => setSelectorIsOpen(!selectorIsOpen)}
      >
        <div className="lang-menu__current">
          <div className="lang-menu__currant-item">
            {currentLang === 'en' ? (
              <>
                <img src={FlagEn} alt="flag en" className="lang-menu__flag" />
                <span className="lang-menu__name">EN</span>
              </>
            ) : null}
            {currentLang === 'uk' ? (
              <>
                <img src={FlagUa} alt="flag ua" className="lang-menu__flag" />
                <span className="lang-menu__name">UK</span>
              </>
            ) : null}
            <svg
              className={`lang-menu__arrow ${selectorIsOpen ? 'lang-menu__arrow_open' : ''}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 12"
              width="24"
              height="12"
              fill="none"
              stroke="#fafafa"
              strokeWidth="1"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="m23 .5-11 11L1 .5" />
            </svg>
          </div>
        </div>
        <ul className={`lang-menu__list ${selectorIsOpen ? 'lang-menu__list_open' : ''}`}>
          <li
            onClick={() => {
              setLanguage('en');
            }}
          >
            <div className="lang-menu__target lang-menu__target_active">
              <img src={FlagEn} className="lang-menu__flag" alt="flag en" />
              <span className="lang-menu__name">EN</span>
            </div>
          </li>
          <li
            onClick={() => {
              setLanguage('uk');
            }}
          >
            <div className="lang-menu__target lang-menu__target_active">
              <img src={FlagUa} className="lang-menu__flag" alt="flag ua" />
              <span className="lang-menu__name">UK</span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default LangMenu;
