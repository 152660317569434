import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Widget } from '@typeform/embed-react';

import ModalAddress from './../components/ModalAddress';
import Logo from './../components/Logo';
import scrollToTop from '../helpers/ScrollToTop';

function Footer() {
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();



  const closeModal = () => {
    setShowModal(false);
    document.querySelector('body').style.overflow = 'visible';
    document.querySelector('body').style.padding = '0';
  };

  const date = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer__container container">
        <div className="footer__content">
          <Logo />
          <nav className="footer__navigation justify-content-between">
            <ul>
              <li className="footer__navigation-item">
                <NavLink to="/webinars" onClick={scrollToTop}>
                  {t('About us')}
                </NavLink>
              </li>
              <li className="footer__navigation-item">
                <NavLink to="/eco-friendly-marketplace" onClick={scrollToTop}>
                  {t('Find Parts')}
                </NavLink>
              </li>
            </ul>
            <ul>
              <li className="footer__navigation-item">
                <NavLink to="/partnership" onClick={scrollToTop}>
                  {t('Partnership')}
                </NavLink>
              </li>
              <li className="footer__navigation-item">
                <NavLink to="/retailers" onClick={scrollToTop}>
                  {t('Retail network')}
                </NavLink>
              </li>
            </ul>
            <ul>
              <li className="footer__navigation-item">
                <NavLink to="/privacy-policy" onClick={scrollToTop}>
                  {t('Privacy policy')}
                </NavLink>
              </li>
            </ul>
            <ul>
              <li className="footer__navigation-item">
                <div className="footer__contact-wrapper">
                  <h4 className="footer__contacts-heading">{t('Contacts')}</h4>
                  <p className="footer__contact-info">
                    <span className="footer__contact-description">
                      <a href="mailto:info@heyboss.online">admin@cars-parts.xyz</a>
                    </span>
                  </p>
                </div>
              </li>
            </ul>
          </nav>
          {i18n.language === 'en' ? (
            <Widget id="dnK9lAGV" inlineOnMobile className="my-form" />
          ) : (
            <Widget id="g0lSl1a4" inlineOnMobile className="my-form" />
          )}
        </div>
        <ModalAddress showModal={showModal} closeModal={closeModal} />
        <p className="footer__no-copyright">© {date}.{t('HEYBOSS. All right reserved')}</p>
      </div>
    </footer>
  );
}

export default Footer;
