import { Outlet } from 'react-router-dom'
import { Suspense } from 'react'
import Header from './Header'
import Footer from './Footer'
import ButtonUp from '../components/ButtonUp'

function Layout() {
  return (
    <div className="page" display="grid" gridtemplaterows="300px 1fr">
      <Header />
      <Suspense>
        <Outlet />
      </Suspense>
      <ButtonUp />
      <Footer />
    </div>
  )
}

export default Layout
