import React, { useState, useEffect, useCallback } from 'react';
import ArrowUp from '../assets/icons/arrow_up.svg';

import scrollToTop from '../helpers/ScrollToTop';

function ButtonUp() {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = useCallback(() => {
    setShowButton(window.scrollY > 800);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, showButton]);

  return (
    <>
      <div className={`go-up ${showButton ? 'go-up_visible' : ''}`} onClick={scrollToTop}>
        <img src={ArrowUp} alt="arrow up" />
        <span className="go-up__text">UP</span>
      </div>
    </>
  );
}

export default ButtonUp;
