import React, { useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';
import { useTranslation } from 'react-i18next';

const ModalAddress = ({ showModal, closeModal }) => {
  const modalInstance = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const modalElement = document.getElementById('myModal');

    if (showModal) {
      modalInstance.current = new Modal(modalElement, {
        backdrop: true,
        keyboard: true,
        focus: true,
      });

      modalInstance.current.show();

      const handleHidden = () => {
        closeModal();
      };
      modalElement.addEventListener('hidden.bs.modal', handleHidden);

      return () => {
        modalInstance.current.dispose();
        modalElement.removeEventListener('hidden.bs.modal', handleHidden);
      };
    }
  }, [showModal, closeModal]);

  if (!showModal) return null;

  return (
    <div className="modal show" tabIndex="-1" id="myModal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t('Our address on the map')}</h5>
            <button type="button" className="btn-close" onClick={closeModal}></button>
          </div>
          <div className="modal-address">
          {i18n.language === 'en' ? (
               <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2747.180587903754!2d30.722159675879507!3d46.484736464772176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c63194904efba7%3A0x3ea694f4c45dd504!2sNizhyns&#39;ka%20St%2C%2034%2C%20Odesa%2C%20Odes&#39;ka%20oblast%2C%20Ukraine%2C%2065000!5e0!3m2!1sen!2s!4v1700212829119!5m2!1sen!2s"
              title="Our Address on Map"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe> 
            ) : (
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2747.180772971119!2d30.7247346!3d46.484732799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c63194904efba7%3A0x3ea694f4c45dd504!2z0LLRg9C70LjRhtGPINCd0ZbQttC40L3RgdGM0LrQsCwgMzQsINCe0LTQtdGB0LAsINCe0LTQtdGB0YzQutCwINC-0LHQu9Cw0YHRgtGMLCDQo9C60YDQsNGX0L3QsCwgNjUwMDA!5e0!3m2!1suk!2s!4v1700213893113!5m2!1suk!2s" 
            title="Our Address on Map"
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"></iframe>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddress;
